var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        right: "",
        "offset-y": "",
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", color: "#f7f7f7" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-apps")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "300", tile: "" } },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-subheader",
                [
                  _vm._v(" Portals "),
                  _c("v-icon", { staticClass: "ml-2", attrs: { small: "" } }, [
                    _vm._v("mdi-launch"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "portal-links-container" },
                _vm._l(_vm.portals, function (portal) {
                  return _c(
                    "v-list-item",
                    {
                      key: portal.key,
                      staticClass: "portal-link",
                      staticStyle: { "min-height": "auto" },
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigate(portal)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "portal-link-content" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-auto",
                              attrs: { plain: "", icon: "" },
                            },
                            [_c("v-icon", [_vm._v(_vm._s(portal.icon))])],
                            1
                          ),
                          _c(
                            "v-list-item-action-text",
                            { staticClass: "pa-0 ma-0" },
                            [_vm._v(" " + _vm._s(portal.name) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }